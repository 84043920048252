import { Route } from '@angular/router';
import { ApiTokenPayload, LocalizedString } from '@apophenia/platform';
import { ArtistsModule } from 'src/app/pages/artists/artists.module';
import { GenericModulesModule } from 'src/app/pages/generic-modules/generic-modules.module';
//import { ApiTokenPayload } from '@apophenia/shared/dist/pareidolia';
import { OrganismModule } from 'src/app/pages/organisms/organisms.module';
import { ProjectsModule } from 'src/app/pages/projects/projects.module';
import { UsersModule } from 'src/app/pages/users/users.module';
import { Func } from 'src/app/shared/utils/functions';
import { LicenseModule } from '../pages/licenses/licenses.module';
import { UserInvitationsModule } from '../pages/invitations/invitations.module';

export interface RouteInfo extends Omit<Route, 'title'> {
  path?: string;
  sidebarActiveFilter?: string;
  reverseActiveFilter?: string;
  sidebarPath?: string;
  label?: string | LocalizedString;
  icon?: string;
  requiredAccess?: RouteAccessLevel | Func<boolean, [ApiTokenPayload]>;
  position?: 'top' | 'bottom';
  visible?: boolean;
}

export type AllowedRoute = Pick<
  RouteInfo,
  | 'label'
  | 'icon'
  | 'requiredAccess'
  | 'position'
  | 'visible'
  | 'sidebarActiveFilter'
  | 'reverseActiveFilter'
> & { path: string };

export enum RouteAccessLevel {
  Admin = 'Admin',
  Client = 'Client',
  Artist = 'Artist',
  Diffuser = 'Diffuser',
}

const appRoutes: RouteInfo[] = [
  ...OrganismModule.RouteInfo,
  ...ProjectsModule.RouteInfo,
  ...GenericModulesModule.RouteInfo,
  ...ArtistsModule.RouteInfo,
  ...UsersModule.RouteInfo,
  ...LicenseModule.RouteInfo,
  ...UserInvitationsModule.RouteInfo,
];

export const getAppRoutes = (): RouteInfo[] => {
  return appRoutes.filter((x) => !!x.path);
};

export const getAllowedRoutes = (accesses: ApiTokenPayload): AllowedRoute[] => {
  return appRoutes
    .filter((r) => {
      if (typeof r.requiredAccess == 'function') {
        return r.requiredAccess(accesses);
      }
      if (accesses.isAdmin) {
        return true;
      }
      switch (r.requiredAccess) {
        case RouteAccessLevel.Admin:
          return false;
        case RouteAccessLevel.Artist:
          return !!accesses.artist;
        case RouteAccessLevel.Client:
          return (
            (accesses.organisms?.length ?? 0) > 0 ||
            (accesses.partners?.length ?? 0) > 0
          );
        case RouteAccessLevel.Diffuser:
          return accesses.diffuser;
        default:
          return true;
      }
    })
    .map((r) => ({
      path: (r.sidebarPath ?? r.path) as string,
      label: r.label,
      icon: r.icon,
      requiredAccess: r.requiredAccess,
      position: r.position,
      visible: r.visible,
      sidebarActiveFilter: r.sidebarActiveFilter ?? r.sidebarPath ?? r.path,
      reverseActiveFilter: r.reverseActiveFilter,
    }));
};
