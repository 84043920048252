<div class="col">
  <nz-collapse *ngIf="user$ | async; let user">
    <nz-collapse-panel
      [nzActive]="true"
      [nzHeader]="translations['current'] | localize"
    >
      <div class="row row-wrap">
        <button
          *ngFor="let license of licenses$ | async"
          class="card license-row"
          [routerLink]="license.url"
        >
          <div class="title">
            {{ license.name }}
          </div>
        </button>
      </div>
    </nz-collapse-panel>
    <nz-collapse-panel [nzHeader]="translations['expired'] | localize">
      <div class="row row-wrap">
        <button
          *ngFor="let license of expired$ | async"
          class="card license-row"
          [routerLink]="license.url"
        >
          <div class="title">
            {{ license.name }}
          </div>
        </button>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</div>
