import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { LocalizeService } from '../../../shared/localization/locale.service';
import { SupportedLocals } from '@apophenia/platform';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isProd = environment.production;

  constructor(
    private authService: AuthService,
    private router: Router,
    public localizer: LocalizeService,
  ) {}

  logout(): void {
    this.authService.logout();
  }
  toggleLocale(): void {
    this.localizer.setLocale(
      this.localizer.currentLocal == SupportedLocals.EN
        ? SupportedLocals.FR
        : SupportedLocals.EN,
    );
  }

  onUserPageClicked(): void {
    void this.router.navigate(['user']);
  }
}
