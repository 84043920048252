<div class="card invitations-card">
  <button [routerLink]="'/invitations'" class="close-button blank">X</button>
  <form *ngIf="formGroup" [formGroup]="formGroup">
    <app-entity-info
      *ngIf="invitation$ | async; let invitation"
      [formGroup]="formGroup"
      [hasImage]="false"
      [entity]="invitation"
      [extraInfo]="{ createdAt: 'Date: {$}' }"
      nameFormControl="given_name"
      familyNameFormControl="family_name"
      (nameChanged)="nameChanged()"
    >
      <div class="form-control">
        <span class="label">{{ translations['admin'] | localize }}</span>
        <app-yes-no-radio
          formControlName="isAdmin"
          (ngModelChange)="updateServer('isAdmin')"
        >
        </app-yes-no-radio>
      </div>
      <ng-container *ngIf="organisms$ | async; let organisms">
        <app-editable-selector-multi
          [fieldTitle]="translations['client']"
          formControlName="organisms"
          (ngModelChange)="updateServer('organisms')"
          [options]="organisms"
        ></app-editable-selector-multi>
        <app-editable-selector-multi
          [fieldTitle]="translations['partner']"
          (ngModelChange)="updateServer('partners')"
          formControlName="partners"
          [options]="organisms"
        ></app-editable-selector-multi>
      </ng-container>
      <app-editable-selector
        [fieldTitle]="translations['artist']"
        [options]="artists$ | async"
        [currentValue]="invitation.artistID"
        formControlName="artistID"
        (ngModelChange)="updateServer('artistID')"
      ></app-editable-selector>
      <app-editable-selector-multi
        [fieldTitle]="translations['licenses']"
        (ngModelChange)="updateServer('licenses')"
        formControlName="licenses"
        [options]="licenses$ | async"
      ></app-editable-selector-multi>
      <!-- <div class="form-control">
        <span class="label">{{ translations['artist'] | localize }}</span>
        <app-yes-no-radio
          formControlName="artistID"
          (ngModelChange)="updateServer('artistID')"
        >
        </app-yes-no-radio>
      </div> -->
      <!-- <app-editable-localized-text
        [fieldTitle]="'Description'"
        [defaultRows]="10"
        formControlName="description"
        (currentValueChange)="updateServer('')"
      >
      </app-editable-localized-text> -->
      <button
        *ngIf="isNewEntity"
        class="primary key-button"
        type="button"
        (click)="submit()"
      >
        Submit
      </button>
    </app-entity-info>
  </form>
</div>
