<div class="col">
  <nz-collapse>
    <ng-container *ngFor="let org of organisms$ | async">
      <nz-collapse-panel
        [nzActive]="org.projects.length > 0"
        [nzHeader]="org.name"
      >
        <div class="row row-wrap">
          <button
            *ngFor="let project of org.projects"
            class="card project-card"
            [routerLink]="project.url"
          >
            <div class="card-img">
              <img [src]="project.img" alt="{{ project.name }}" />
            </div>
            <div class="title">
              {{ project.name }}
            </div>
          </button>
        </div>
      </nz-collapse-panel>
    </ng-container>
  </nz-collapse>
</div>
