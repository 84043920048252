<div class="card user-card">
  <app-tabs
    *ngIf="isAdmin$ | async"
    [tabs]="tabsConfigs"
    [(currentTab)]="currentTab"
  ></app-tabs>
  <button
    *ngIf="isAdmin$ | async"
    [routerLink]="'/users'"
    class="close-button blank"
  >
    X
  </button>
  <div class="info-tab" *ngIf="currentTab === UserTabs.Information">
    <div class="section expand" *ngIf="user$ | async; let user">
      <div class="name-id-section" [formGroup]="metadataGroup">
        <div class="name-lastname">
          <app-editable-text
            [textStyle]="{
              color: 'white',
              fontSize: '50px',
              lineHeight: '50px'
            }"
            fieldTitle=" "
            textClass="section-title"
            formControlName="given_name"
            (currentValueChange)="updateMetadata('given_name')"
          >
          </app-editable-text>

          <app-editable-text
            [textStyle]="{
              color: 'white',
              fontSize: '50px',
              lineHeight: '50px'
            }"
            textClass="section-title"
            formControlName="family_name"
            fieldTitle=" "
            (currentValueChange)="updateMetadata('family_name')"
          >
          </app-editable-text>
        </div>
        <span class="id-label"> ID: {{ user.id }} </span>
      </div>
      <div class="form-section"></div>
    </div>
    <!-- <div class="section img-section" *ngIf="hasImage">
      <app-image-card
        maxSize="400px"
        [img]="publicImage ?? missingImage"
        [readonly]="readonlyImage"
      ></app-image-card>
    </div> -->
  </div>
  <div class="info-tab padded" *ngIf="currentTab === UserTabs.Admin">
    <button
      class="primary key-button"
      *ngIf="user$ | async; let user"
      nz-popconfirm="{{ translations['delete'] | localize }}"
      (nzOnConfirm)="deleteUser(user.credentials.email)"
    >
      <i nz-icon nzType="delete" nzTheme="outline"></i>
    </button>
  </div>
</div>
