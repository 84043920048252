import { CommonModule } from '@angular/common';
import { NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CanActivateFn, Router, RouterModule } from '@angular/router';
import { Observable, map } from 'rxjs';
import { RouteAccessLevel, RouteInfo } from 'src/app/layout/routes';
import { UsersCardComponent } from 'src/app/pages/users/users-card/users-card.component';
import { UsersListComponent } from 'src/app/pages/users/users-list/users-list.component';
import { UsersService } from 'src/app/pages/users/users.service';
import { CoreModule } from 'src/app/shared/components/core.module';
import { DynamicFormItemsModule } from 'src/app/shared/components/dynamic-form-items/dynamic-form-items.module';
import { EntityInfoModule } from 'src/app/shared/components/entity-info/entity-info.module';
import { PlatformFileModule } from 'src/app/shared/components/platform-files/platform-files.module';
import { LocalizeModule } from 'src/app/shared/localization/localize.module';
import { AuthService } from 'src/app/shared/services/auth.service';
import { createServiceResolver } from 'src/app/shared/utils/resolve-dataservice';

const canViewModuleList: CanActivateFn = (): Observable<boolean> => {
  const auth = inject(AuthService);
  const router = inject(Router);
  return auth.tokenPayload$.pipe(
    map((user) => {
      const allowed = user?.isAdmin ?? false;
      if (!allowed && user) {
        void router.navigate([`/users/${user.id}`]);
      }
      return allowed;
    }),
  );
};
@NgModule({
  declarations: [UsersListComponent, UsersCardComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormItemsModule,
    EntityInfoModule,
    LocalizeModule,
    PlatformFileModule,
    RouterModule.forChild([
      {
        path: '',
        canActivate: [canViewModuleList],
        component: UsersListComponent,
      },
      { path: ':id', component: UsersCardComponent },
    ]),
  ],
})
export class UsersModule {
  static get RouteInfo(): RouteInfo[] {
    return [
      {
        path: 'users',
        loadChildren: () => import('./users.module').then((m) => m.UsersModule),
        resolve: {
          users: createServiceResolver(UsersService),
        },
        label: { FR: 'Utilisateurs', EN: 'Users' },
        icon: 'user',
        visible: true,
        requiredAccess: RouteAccessLevel.Client,
      },
      {
        path: 'users/:id',
        sidebarPath: 'users/current',
        sidebarActiveFilter: 'users',
        position: 'bottom',
        loadChildren: () => import('./users.module').then((m) => m.UsersModule),
        resolve: {
          users: createServiceResolver(UsersService),
        },
        label: { FR: 'Profil', EN: 'Profile' },
        icon: 'user',
        visible: true,
      },
    ];
  }
}
