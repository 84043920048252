<div class="navbar">
  <span></span>
  <span [style]="{ visibility: isProd ? 'hidden' : 'visible' }"
    >Environnement Develop</span
  >
  <div class="menu-container">
    <button class="primary" (click)="toggleLocale()">
      {{ localizer.currentLocal === 'EN' ? 'FR' : 'EN' }}
    </button>
    <a
      nz-dropdown
      nzTrigger="click"
      class="nav-link"
      href="javascript:void(0)"
      [nzDropdownMenu]="menu"
    >
      <div nz-row class="user-menu-click">
        <div nz-col nz-flex="2">
          <div class="user-menu-image">
            <img alt="Profile Photo" src="assets/img/borgar.svg" />
          </div>
        </div>
      </div>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item>
          <button class="btn btn-secondary" (click)="logout()">Log out</button>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
