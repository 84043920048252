import { Injectable } from '@angular/core';
import {
  CreateProjectDTO,
  Memory,
  Project,
  ProjectTemplate,
  UpdateProjectDTO,
  getThumbnail,
} from '@apophenia/platform';
import { MAIN_SPINNER_NAME } from 'src/app/app.component';
import { ProjectViewModel } from 'src/app/pages/projects/projects.models';
import { CommonDataService } from 'src/app/shared/services/_base-data.service';

@Injectable({ providedIn: 'root' })
export class ProjectsService extends CommonDataService<
  Project,
  ProjectViewModel
> {
  protected override get baseURL(): string {
    return `/organisms/projects`;
  }

  getProjectEndpoint(id: string): string {
    return `/organisms/${this.state$.value.entities[id].organismID}/projects/${id}`;
  }

  createProject(
    organismID: string,
    dto: CreateProjectDTO,
  ): Promise<ProjectViewModel> {
    return super.createOne(dto, `/organisms/${organismID}/projects`);
  }
  updateProject(
    projectID: string,
    dto: UpdateProjectDTO<Partial<ProjectTemplate>>,
  ): Promise<ProjectViewModel> {
    const organism = this.getEntity(projectID).organismID;
    return super.updateOne(projectID, dto, `/organisms/${organism}/projects`);
  }

  async deleteProject(id: string): Promise<void> {
    await this.apiService.delete<Project>(
      this.getProjectEndpoint(id),
      MAIN_SPINNER_NAME,
    );
    this.removeOneEntity(id);
  }
  protected override getFileURL(id: string, fileID?: string): string {
    return `${this.getProjectEndpoint(id)}/files${fileID ? '/' + fileID : ''}`;
  }
  protected override viewModel(entity: Project): ProjectViewModel {
    const thumbnail = getThumbnail(entity);
    return {
      ...entity,
      thumbnail,
      hasThumbnail: !!thumbnail,
      manifests: entity.manifests as Memory[],
    };
  }
}
