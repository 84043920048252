<div class="col">
  <app-action-bar> </app-action-bar>
  <div class="row row-wrap">
    <button
      *ngFor="let org of organisms$ | async"
      class="card org-card"
      [routerLink]="org.url"
    >
      <div class="card-img">
        <img [src]="org.img" alt="{{ org.name }}" />
      </div>
      <div class="title">
        {{ org.name }}
      </div>
    </button>
  </div>
</div>
