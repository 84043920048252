import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  EntityFormControls,
  NotNullControl,
} from 'src/app/shared/utils/form-controls';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';
import {
  CreateProjectDTO,
  CreateProjectManifestDTO,
  DeviceTypes,
  LocalizedString,
  Memory,
  ProjectCategories,
  UpdateProjectDTO,
  VRProject,
  VRProjectManifestType,
} from '@apophenia/platform';
import { ProjectsService } from 'src/app/pages/projects/projects.service';
import { ProjectViewModel } from 'src/app/pages/projects/projects.models';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import { LocalizedDict } from 'src/app/shared/localization/lozalize.models';
import { firstValuePromise } from 'src/app/shared/utils/promisify';
import { MemoryFileUpdateEvent } from 'src/app/shared/components/platform-files/platform-files-info/platform-files-info.component';
import { ActivatedRoute } from '@angular/router';

type ProjectFormControls = EntityFormControls<
  CreateProjectDTO<VRProject> & {
    id?: string;
  },
  'metadata'
>;

export const VR_PROJECT_TABS: TabsModuleConfigs = [
  {
    label: 'Information',
  },
  {
    label: { EN: 'Scene', FR: 'Scène' },
  },
  {
    label: 'Audio',
  },
  {
    label: { EN: 'Interactive Graph', FR: "Graph d'intéraction " },
    disabled: true,
  },
  {
    label: 'Sources',
    disabled: true,
  },
];

@Component({
  selector: 'app-vr-projects-card',
  templateUrl: './vr-projects-card.component.html',
  styleUrls: ['./vr-projects-card.component.scss'],
})
export class VRProjectsCardComponent implements OnChanges {
  @Input() project?: ProjectViewModel | null;
  @Input() currentTab = 0;
  //@Output() updateMetadata = new EventEmitter<ProjectTemplate>();

  VRProjectManifestType = VRProjectManifestType;
  currentScenes: Memory[] = [];
  currentAudio: Memory[] = [];

  formGroup?: FormGroup<ProjectFormControls>;
  isAdmin$: Observable<boolean>;

  translations: LocalizedDict = {
    createdAt: { EN: 'Creation date:', FR: 'Date de création' },
    video: { EN: 'Video preview URL:', FR: 'Lien vidéo de présentation:' },
  };

  constructor(
    private currentUserService: CurrentUsersService,
    private projectService: ProjectsService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.isAdmin$ = this.currentUserService.isAdmin$;
    this.createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.project?.currentValue) {
      this.updateFormGroup(this.project);
      this.currentScenes =
        this.project?.manifests.filter(
          (x) => x.groupID == VRProjectManifestType.Scene,
        ) ?? [];
      this.currentAudio =
        this.project?.manifests.filter(
          (x) => x.groupID == VRProjectManifestType.SoundBank,
        ) ?? [];
    }
  }

  updateFormGroup(proj?: ProjectViewModel | null): void {
    this.formGroup?.setValue({
      id: proj?.id as string,
      organismID: proj?.organismID as string,
      name: proj?.name as string,
      metadata: {
        videoPreview: proj?.metadata.videoPreview,
        artists: proj?.metadata.artists ?? [],
        curators: proj?.metadata.curators ?? [],
        creationDate: proj?.metadata.creationDate ?? new Date(),
        description: proj?.metadata.description ?? {},
      },
      isFree: proj?.isFree ?? false,
      isDev: proj?.isDev ?? false,
      isPrivate: proj?.isPrivate ?? false,
      platformVersion: proj?.platformVersion ?? '0.0.0',
      categories: proj?.categories ?? [],
    });
  }

  createFormGroup(): void {
    this.formGroup = this.formBuilder.group<ProjectFormControls>({
      id: NotNullControl('', [Validators.required]),
      organismID: NotNullControl('', [Validators.required]),
      name: NotNullControl('', [Validators.required]),
      metadata: this.formBuilder.group<EntityFormControls<VRProject>>({
        videoPreview: NotNullControl(''),
        artists: NotNullControl<string[]>([]),
        curators: NotNullControl<string[]>([]),
        creationDate: NotNullControl<Date>(new Date(), [Validators.required]),
        description: NotNullControl<LocalizedString>({}),
      }),
      isFree: NotNullControl(true),
      isDev: NotNullControl(false),
      isPrivate: NotNullControl(true),
      platformVersion: NotNullControl('0.0.0', [Validators.required]),
      categories: NotNullControl<ProjectCategories[]>([]),
    });
  }

  async updateServer(key: keyof UpdateProjectDTO): Promise<void> {
    const dto: UpdateProjectDTO = {
      [key]: this.formGroup?.controls[key].value,
    };
    await this.projectService.updateProject(
      this.formGroup?.controls.id.value as string,
      dto,
    );
  }

  async updateMetadata(key: keyof VRProject): Promise<void> {
    const dto: UpdateProjectDTO<Partial<VRProject>> = {
      metadata: {
        [key]: this.formGroup?.controls.metadata.controls[key].value,
      },
    };
    await this.projectService.updateProject(
      this.formGroup?.controls.id.value as string,
      dto,
    );
  }

  async uploadFile(
    file: MemoryFileUpdateEvent,
    type: VRProjectManifestType,
  ): Promise<void> {
    const params = await firstValuePromise(this.route.params);
    if (!params?.id) {
      return;
    }
    const dto: Partial<CreateProjectManifestDTO> = {
      id: file.memory?.id,
      device:
        (type == VRProjectManifestType.Scene ||
        type == VRProjectManifestType.SoundBank
          ? file.memory?.device
          : DeviceTypes.Universal) ?? DeviceTypes.Universal,
      groupID: type,
    };
    await this.projectService.uploadFile(
      params.id as string,
      {
        fileUpload: { file: file.file },
        dto,
      },
      'manifests',
    );
  }
}
