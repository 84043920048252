import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { LicenseViewModel } from '../license.models';
import { LicenseService } from '../license.service';
import { LocalizedDict } from '../../../shared/localization/lozalize.models';

type UserLicenseList = {
  id: string;
  name: string;
  licenses: (LicenseViewModel & { url: string })[];
  expired: (LicenseViewModel & { url: string })[];
};

@Component({
  selector: 'app-admin-license-list',
  templateUrl: './license-admin-list.component.html',
  styleUrls: ['./license-admin-list.component.scss'],
})
export class LicenseAdminListComponent {
  licenses$: Observable<UserLicenseList[]>;

  translations: LocalizedDict = {
    expired: { EN: 'Expired', FR: 'Expirées' },
    licenses: { EN: 'Licenses', FR: 'Licences' },
  };

  constructor(private licenseService: LicenseService) {
    this.licenses$ = this.licenseService.selectAll().pipe(
      map((x) => {
        const dict = x.reduce((d, l) => {
          if (!d[l.userID]) {
            d[l.userID] = {
              id: l.userID,
              name: l.user
                ? `${l.user.metadata.given_name ?? ''} ${
                    l.user.metadata.family_name ?? ''
                  }`
                : 'unknown',
              licenses: [],
              expired: [],
            };
          }
          if (l.expired) {
            d[l.userID].expired.push({ ...l, url: `/licenses/${l.id}` });
          } else {
            d[l.userID].licenses.push({ ...l, url: `/licenses/${l.id}` });
          }
          return d;
        }, {} as Record<string, UserLicenseList>);
        return Object.keys(dict)
          .map((key) => dict[key])
          .sort((a, b) => a.name.localeCompare(b.name));
      }),
    );
  }
}
