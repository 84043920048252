import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { YesNoRadioComponent } from 'src/app/shared/components/yes-no-radio/yes-no-radio.component';
import { SelectorComponent } from 'src/app/shared/components/selector/selector.component';
import { ActionBarComponent } from 'src/app/shared/components/action-bar/action-bar.component';
import { AppSpinnerComponent } from 'src/app/shared/components/app-spinner/app-spinner.component';
import { DragAndDropDirective } from 'src/app/shared/components/drag-and-drop/drag-and-drop.directive';
import { MultipleTabsComponent } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import { NgZorroModule } from 'src/app/shared/components/ng-zorro.module';
import { TooltipModule } from 'src/app/shared/components/tooltip/tooltip.module';
import { DragAndDropButtonComponent } from 'src/app/shared/components/drag-and-drop/drag-and-drop-button.component';
import { ImageCardItemComponent } from 'src/app/shared/components/image-card/image-card.component';
import { ClipboardModule } from 'ngx-clipboard';
import { LocalizeModule } from 'src/app/shared/localization/localize.module';
import { MultipleTabsModule } from 'src/app/shared/components/multiple-tabs/multiple-tabs.module';
import { UploadTrackerComponent } from 'src/app/shared/components/upload-tracker/upload-tracker.component';

@NgModule({
  declarations: [
    AppSpinnerComponent,
    DragAndDropDirective,
    YesNoRadioComponent,
    SelectorComponent,
    ActionBarComponent,
    DragAndDropButtonComponent,
    ImageCardItemComponent,
    UploadTrackerComponent,
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    NgZorroModule,
    NgxDatatableModule,
    FormsModule,
    ClipboardModule,
    LocalizeModule,
    MultipleTabsModule,
  ],
  exports: [
    ClipboardModule,
    FormsModule,
    NgZorroModule,
    AppSpinnerComponent,
    TooltipModule,
    NgxSpinnerModule,
    DragAndDropDirective,
    MultipleTabsComponent,
    YesNoRadioComponent,
    SelectorComponent,
    ActionBarComponent,
    DragAndDropButtonComponent,
    ImageCardItemComponent,
    MultipleTabsModule,
    UploadTrackerComponent,
  ],
})
export class CoreModule {}
