import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '../../shared/components/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormItemsModule } from '../../shared/components/dynamic-form-items/dynamic-form-items.module';
import { LocalizeModule } from '../../shared/localization/localize.module';
import { RouterModule } from '@angular/router';
import { RouteAccessLevel, RouteInfo } from '../../layout/routes';
import { createServiceResolver } from '../../shared/utils/resolve-dataservice';
import { OrganismsService } from '../organisms/organisms.service';
import { InvitationsService } from './invitations.service';
import { UserInvitationListComponent } from './invitations-list/invitations-list.component';
import { UserInvitationCardComponent } from './invitation-card/invitation-card.component';
import { EntityInfoModule } from '../../shared/components/entity-info/entity-info.module';
import { LicenseService } from '../licenses/license.service';
import { ArtistsService } from '../artists/artists.service';

@NgModule({
  declarations: [UserInvitationListComponent, UserInvitationCardComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormItemsModule,
    EntityInfoModule,
    LocalizeModule,
    RouterModule.forChild([
      { path: '', component: UserInvitationListComponent },
      { path: ':id', component: UserInvitationCardComponent },
    ]),
  ],
})
export class UserInvitationsModule {
  static get RouteInfo(): RouteInfo[] {
    return [
      {
        path: 'invitations',
        loadChildren: () =>
          import('./invitations.module').then((m) => m.UserInvitationsModule),
        resolve: {
          artists: createServiceResolver(ArtistsService),
          organisms: createServiceResolver(OrganismsService),
          licenses: createServiceResolver(LicenseService),
          invitations: createServiceResolver(InvitationsService),
        },
        label: 'Invitations',
        icon: 'red-envelope',
        visible: true,
        requiredAccess: RouteAccessLevel.Admin,
      },
    ];
  }
}
