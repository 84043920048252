import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { InvitationsService } from '../invitations.service';
import { UserInvitation } from '@apophenia/platform';

export type UserInvitationListItem = Pick<UserInvitation, 'id'> & {
  name: string;
  url: string;
};

@Component({
  selector: 'app-invitations-list',
  templateUrl: './invitations-list.component.html',
  styleUrls: ['./invitations-list.component.scss'],
})
export class UserInvitationListComponent {
  invitations$: Observable<UserInvitationListItem[]>;

  constructor(private invitationService: InvitationsService) {
    this.invitations$ = this.invitationService.selectAll().pipe(
      map((x) =>
        x.map((i) => ({
          ...i,
          url: `/invitations/${i.id}`,
          name: `${i.given_name} ${i.family_name ?? ''}`,
        })),
      ),
    );
  }
}
