<div class="card license-card">
  <button [routerLink]="'/licenses'" class="close-button blank">X</button>
  <form *ngIf="formGroup" [formGroup]="formGroup">
    <app-entity-info
      *ngIf="license$ | async; let license"
      [formGroup]="formGroup"
      [hasImage]="false"
      [entity]="license"
      [extraInfo]="{
        expired: 'Exp: {$}',
        stripeID: 'Stripe: {$}',
        userID: 'User: {$}'
      }"
      (nameChanged)="updateServer('name')"
    >
      <!-- <app-tabs
        *ngIf="tabsConfigs"
        [tabs]="tabsConfigs"
        [(currentTab)]="currentTab"
      ></app-tabs> -->
      <app-editable-selector
        [fieldTitle]="translations['user']"
        formControlName="userID"
        (ngModelChange)="updateServer('userID')"
        [options]="users$ | async"
        [readonly]="(isAdmin$ | async) !== true"
      ></app-editable-selector>
      <app-editable-radio
        [fieldTitle]="translations['isFullAccess']"
        formControlName="isFullAccess"
        (ngModelChange)="updateServer('isFullAccess')"
        [options]="yesNoChoices"
        [readonly]="(isAdmin$ | async) !== true"
      >
      </app-editable-radio>
      <ng-container *ngIf="!formGroup.controls.isFullAccess.value">
        <app-editable-selector-multi
          [fieldTitle]="translations['projects']"
          formControlName="projectIDs"
          (ngModelChange)="updateServer('projectIDs')"
          [options]="projects$ | async"
          [readonly]="(isAdmin$ | async) !== true"
        ></app-editable-selector-multi>
      </ng-container>
      <app-editable-date
        [fieldTitle]="translations['startedAt']"
        formControlName="startedAt"
        [readonly]="(isAdmin$ | async) !== true"
      ></app-editable-date>
      <app-editable-date
        [fieldTitle]="translations['expiresAt']"
        formControlName="expiresAt"
        [readonly]="(isAdmin$ | async) !== true"
      ></app-editable-date>
      <app-editable-radio
        *ngIf="isAdmin$ | async"
        [fieldTitle]="translations['expired']"
        formControlName="expired"
        (ngModelChange)="updateServer('expired')"
        [options]="yesNoChoices"
      >
      </app-editable-radio>
      <button
        *ngIf="isNewEntity"
        class="primary key-button"
        type="button"
        (click)="submit()"
      >
        Submit
      </button>
    </app-entity-info>
  </form>
</div>
