import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import {
  ApiTokenPayload,
  CredentialTokens,
  DashboardLoginDTO,
  SignupDTO,
} from '@apophenia/platform';
import { CurrentUsersService } from 'src/app/shared/services/current-users.service';
import dayjs from 'dayjs';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

export const auth_token_storage_key = 'apophenia_tokens';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public token$ = new BehaviorSubject<string | undefined>(undefined);
  public tokenPayload$ = new BehaviorSubject<ApiTokenPayload | undefined>(
    undefined,
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private currentUser: CurrentUsersService,
    private message: NotificationsService,
  ) {
    const entry = localStorage.getItem(auth_token_storage_key) as string;
    if (entry) {
      const parsed = JSON.parse(entry) as CredentialTokens;
      this.validateAndLoadToken(parsed);
    }
  }

  authenticate$(): Observable<ApiTokenPayload | undefined> {
    return this.tokenPayload$.pipe(
      map((token) => {
        if (token == undefined) {
          void this.router.navigate(['/login']);
          return undefined;
        } else {
          this.currentUser.loadFromToken(token);
        }
        return token;
      }),
    );
  }

  async login(logins: DashboardLoginDTO): Promise<void> {
    try {
      const tokens = await firstValueFrom(
        this.http.post<CredentialTokens>(
          `${environment.auth_domain}/authorize`,
          logins,
        ),
      );
      localStorage.setItem(auth_token_storage_key, JSON.stringify(tokens));
      this.validateAndLoadToken(tokens);
      void this.router.navigate(['/']);
    } catch (e) {
      this.message.error({ EN: 'Invalid login', FR: 'Identifiants invalides' });
      console.error(e);
    }
  }

  async signup(dto: SignupDTO): Promise<void> {
    try {
      await firstValueFrom(
        this.http.post(`${environment.auth_domain}/signup`, dto),
      );
      this.message.success({
        EN: 'Account creation success. Please login to continue',
        FR: 'Votre compte a été créé avec succès. Veuillez vous connecter pour poursuivre.',
      });
      void this.router.navigate(['/login'], { onSameUrlNavigation: 'reload' });
    } catch (e) {
      this.message.error({
        EN: 'There was an error creating your account',
        FR: 'Une erreur est survenue lors de la création de votre compte',
      });
      console.error(e);
    }
  }

  logout(): void {
    localStorage.removeItem(auth_token_storage_key);
    this.token$.next(undefined);
    this.tokenPayload$.next(undefined);
    void this.router.navigate(['/login']);
  }

  private validateAndLoadToken(tokens?: CredentialTokens): void {
    try {
      if (!tokens?.access_token) {
        throw 'logged out';
      }
      const payload = jwt_decode<ApiTokenPayload>(tokens.access_token);
      if (dayjs().isAfter(dayjs.unix(payload.exp))) {
        throw 'logged out';
      }
      this.token$.next(tokens.access_token);
      this.tokenPayload$.next(payload);
      this.currentUser.loadFromToken(payload);
    } catch (e) {
      return void this.router.navigate(['/login']);
    }
  }
}
