<div class="col">
  <app-action-bar>
    <button class="primary key-button" [routerLink]="'/licenses/new'">+</button>
  </app-action-bar>
  <nz-collapse>
    <ng-container *ngFor="let userList of licenses$ | async">
      <nz-collapse-panel
        [nzActive]="userList.licenses.length > 0"
        [nzHeader]="userList.name"
      >
        <span class="type-title">{{
          translations['licenses'] | localize
        }}</span>
        <button
          *ngFor="let licence of userList.licenses"
          class="card license-row"
          [routerLink]="licence.url"
        >
          <div class="title">
            {{ licence.name }}
          </div>
        </button>
        <span class="type-title">{{ translations['expired'] | localize }}</span>
        <button
          *ngFor="let licence of userList.expired"
          class="card license-row expired"
          [routerLink]="licence.url"
        >
          <div class="title">
            {{ licence.name }}
          </div>
        </button>
      </nz-collapse-panel>
    </ng-container>
  </nz-collapse>
</div>
