import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import dayjs from 'dayjs';
import { EditableFormItem } from 'src/app/shared/components/dynamic-form-items/_editable-form';

@Component({
  selector: 'app-editable-date',
  templateUrl: './editable-date.component.html',
  styleUrls: ['./editable-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableDateComponent),
      multi: true,
    },
  ],
})
export class EditableDateComponent extends EditableFormItem<
  Date | undefined,
  Date | string | number | undefined
> {
  @Input() textStyle?: Partial<CSSStyleDeclaration>;
  @Input() editingStyle?: Partial<CSSStyleDeclaration>;
  @Input() format?: string;
  @Input() unix = false;

  override checkHasChanged(oldValue?: Date, newValue?: Date): boolean {
    return !dayjs(oldValue).isSame(newValue);
  }

  protected override transformInput(
    value?: Date | string | number,
  ): Date | undefined {
    if (typeof value == 'string') {
      return dayjs(value, this.format).toDate();
    } else if (this.unix || typeof value == 'number') {
      return dayjs.unix(value as number).toDate();
    }
    return value;
  }

  protected override transformOutput(
    value?: Date,
  ): Date | string | number | undefined {
    if (this.unix) {
      return dayjs(value).unix();
    } else if (this.format) {
      return dayjs(value).format(this.format);
    }
    return value;
  }
}
