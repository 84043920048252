import { Injectable, Type } from '@angular/core';
import {
  CommonDataService,
  DataStore,
} from '../../shared/services/_base-data.service';
import { DiffusionLicense, Memory } from '@apophenia/platform';
import { ProjectsService } from '../projects/projects.service';
import { LicenseViewModel } from './license.models';
import dayjs from 'dayjs';
import { UsersService } from '../users/users.service';

@Injectable({ providedIn: 'root' })
export class LicenseService extends CommonDataService<
  DiffusionLicense,
  LicenseViewModel
> {
  protected get baseURL(): string {
    return '/licenses';
  }

  protected override get loadDependencies(): Type<DataStore<unknown>>[] {
    return [ProjectsService, UsersService];
  }

  protected override viewModel(entity: DiffusionLicense): LicenseViewModel {
    const projectService = this.getDependency(ProjectsService);
    const usersService = this.getDependency(UsersService);
    const projects = (entity.projectIDs ?? []).map(
      (x) => projectService.state.entities[x],
    );
    const user = usersService.state.entities[entity.userID];
    return {
      ...entity,
      projects,
      user,
      documents: entity.documents as Memory[],
      expired: entity.expired ? true : dayjs().isAfter(entity.expiresAt),
      projectCount: entity.isFullAccess
        ? '-'
        : entity.projectIDs.length.toFixed(0),
      expiration: dayjs(entity.expiresAt).format('YYYY-MM-DD'),
    };
  }
}
