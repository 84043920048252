import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizedString, User, UserMetadata } from '@apophenia/platform';
import { Observable, switchMap, tap } from 'rxjs';
import { UsersService } from 'src/app/pages/users/users.service';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import {
  CurrentUsersService,
  UpdateUserMetadataDTO,
} from 'src/app/shared/services/current-users.service';
import {
  EntityFormControls,
  NotNullControl,
} from 'src/app/shared/utils/form-controls';
import { LocalizedDict } from '../../../shared/localization/lozalize.models';

type MetadataControls = EntityFormControls<
  Pick<
    UserMetadata,
    'id' | 'given_name' | 'family_name' | 'about' | 'country' | 'phone'
  > & { id: string }
>;

enum UserTabs {
  Information,
  Logins,
  Accesses,
  Admin,
}

const USER_TABS: TabsModuleConfigs = [
  {
    label: 'Information',
  },
  {
    label: { EN: 'Logins', FR: 'Identifiants' },
  },
  {
    label: { EN: 'Accesses', FR: 'Accès' },
  },
  {
    label: 'Admin',
    adminOnly: true,
  },
];

@Component({
  selector: 'app-users-card',
  templateUrl: './users-card.component.html',
  styleUrls: ['./users-card.component.scss'],
})
export class UsersCardComponent {
  UserTabs = UserTabs;
  tabsConfigs = USER_TABS;
  currentTab: UserTabs = 0;

  isAdmin$: Observable<boolean>;

  metadataGroup!: FormGroup<MetadataControls>;

  user$: Observable<User>;

  translations: LocalizedDict = {
    delete: {
      EN: 'Are you sure you want to delete this user?',
      FR: 'Êtes-vous certain de vouloir supprimer cet utilisateur?',
    },
  };

  constructor(
    private currentUser: CurrentUsersService,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.isAdmin$ = this.currentUser.isAdmin$;
    this.user$ = this.route.params.pipe(
      switchMap((params) => {
        const id =
          !params?.id || params?.id == 'current'
            ? this.currentUser.userID
            : (params.id as string);
        return this.usersService.selectById(id as string);
      }),
      tap((user) => {
        if (this.metadataGroup?.value?.id != user?.id) {
          this.updateFormGroups(user);
        }
      }),
    );
    this.createFormGroups();
  }

  async updateMetadata(key: keyof MetadataControls): Promise<void> {
    const id =
      this.currentUser.userID == this.metadataGroup.controls.id.value
        ? 'self'
        : this.metadataGroup.controls.id.value;
    const dto: Partial<UpdateUserMetadataDTO> = {
      [key]: this.metadataGroup.controls[key].value,
    };
    await this.usersService.updateMetadata(id, dto);
  }

  async deleteUser(email: string): Promise<void> {
    await this.usersService.deleteOne(email);
    await this.router.navigate(['/users']);
  }

  private updateFormGroups(user: User): void {
    this.metadataGroup.setValue({
      id: user.id,
      given_name: user.metadata.given_name,
      family_name: user.metadata.family_name,
      about: user.metadata.about,
      country: user.metadata.country,
      phone: user.metadata.phone,
    });
  }

  private createFormGroups(): void {
    this.metadataGroup = this.formBuilder.group<MetadataControls>({
      id: NotNullControl<string>(''),
      given_name: NotNullControl(''),
      family_name: NotNullControl(''),
      about: NotNullControl<LocalizedString>({}),
      // birthDate: NotNullControl<string>(undefined),
      country: NotNullControl<string>(''),
      phone: NotNullControl<string | undefined>(undefined),
    });
  }
}
