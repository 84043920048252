import { Injectable } from '@angular/core';
import { LocalizedString } from '@apophenia/platform';
import { ToastrService } from 'ngx-toastr';
import { LocalizeService } from '../localization/locale.service';

export enum NotificationType {
  Success = 'primary',
  Error = 'info',
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private toastr: ToastrService,
    private localizer: LocalizeService,
  ) {}

  error(message: string | LocalizedString, title = 'Error'): void {
    const msg =
      typeof message == 'string'
        ? message
        : message[this.localizer.currentLocal];
    this.toastr.error(msg, title, {
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-top-center',
    });
  }

  success(message: string | LocalizedString, title = 'Success'): void {
    const msg =
      typeof message == 'string'
        ? message
        : message[this.localizer.currentLocal];
    this.toastr.info(msg, title, {
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-top-center',
    });
  }
}
