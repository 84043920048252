import { ChangeDetectorRef, Component } from '@angular/core';
import { SelectOption } from '../../../shared/components/selector/selector.component';
import { map, Observable, switchMap, tap } from 'rxjs';
import { LicenseViewModel } from '../license.models';
import { LicenseService } from '../license.service';
import { ProjectsService } from '../../projects/projects.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CreateDiffusionLicenseDTO,
  DeviceTypes,
  MemoryEncodingRequestDTO,
} from '@apophenia/platform';
import {
  EntityFormControls,
  NotNullControl,
} from '../../../shared/utils/form-controls';
import { v4 as uuid } from 'uuid';
import { LocalizedDict } from '../../../shared/localization/lozalize.models';
import dayjs from 'dayjs';
import { CurrentUsersService } from '../../../shared/services/current-users.service';
import { yesNoChoices } from '../../../shared/models/translations.models';
import { UsersService } from '../../users/users.service';
import { TabsModuleConfigs } from 'src/app/shared/components/multiple-tabs/multiple-tabs.component';
import { firstValuePromise } from '../../../shared/utils/promisify';
import { MemoryFileUpdateEvent } from '../../../shared/components/platform-files/platform-files-info/platform-files-info.component';

type LicenseFormControls = EntityFormControls<
  Partial<CreateDiffusionLicenseDTO> & {
    id?: string;
  }
>;

@Component({
  selector: 'app-license-card',
  templateUrl: './license-card.component.html',
  styleUrls: ['./license-card.component.scss'],
})
export class LicenseCardComponent {
  tabsConfigs?: TabsModuleConfigs = [
    {
      label: 'Information',
    },
    {
      label: 'Documents',
    },
  ];
  currentTab = 1;

  isNewEntity = false;
  suspendUpdate = false;
  isAdmin$: Observable<boolean>;
  license$: Observable<LicenseViewModel>;
  projects$: Observable<SelectOption<string>[]>;
  users$: Observable<SelectOption<string>[]>;

  formGroup?: FormGroup<LicenseFormControls>;
  yesNoChoices = yesNoChoices;

  translations: LocalizedDict = {
    isFullAccess: {
      EN: 'Total catalogue access',
      FR: 'Accès à tout le catalogue',
    },
    expired: { EN: 'Expired', FR: 'Expirée' },
    projects: { EN: 'Projects', FR: 'Projets' },
    startedAt: { EN: 'Start date', FR: 'Début' },
    expiresAt: { EN: 'End Date', FR: 'Fin' },
    user: { EN: 'Linked user', FR: 'Utilisateur associé' },
  };

  constructor(
    private currentUser: CurrentUsersService,
    private usersService: UsersService,
    private projectsService: ProjectsService,
    private licenseService: LicenseService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.isAdmin$ = this.currentUser.isAdmin$;
    this.isNewEntity = false;
    this.createFormGroup();
    this.license$ = this.route.params.pipe(
      map((params) => params.id as string),
      tap((id) => (this.isNewEntity = id == 'new')),
      switchMap((id) => this.licenseService.selectById(id)),
      map((license) => license ?? { id: uuid() }),
      tap((n) => {
        if (this.formGroup?.value?.id != n?.id) {
          this.updateFormGroup(n);
        }
      }),
    );
    this.projects$ = this.projectsService
      .selectAll()
      .pipe(map((x) => x.map((o) => ({ value: o.id, label: o.name }))));
    this.users$ = this.usersService.selectAll().pipe(
      map((x) =>
        x.map((o) => ({
          value: o.id,
          label: `${o.metadata.given_name ?? '-'} ${
            o.metadata.family_name ?? '-'
          }`,
        })),
      ),
    );
  }

  updateFormGroup(license?: LicenseViewModel): void {
    this.suspendUpdate = true;
    this.formGroup?.setValue({
      id: (license?.id as string) ?? uuid(),
      name: license?.name ?? '',
      isFullAccess: license?.isFullAccess ?? false,
      projectIDs: license?.projectIDs ?? [],
      startedAt: (license?.startedAt as Date) ?? dayjs().toDate(),
      expiresAt:
        (license?.expiresAt as Date) ?? dayjs().add(6, 'month').toDate(),
      expired: license?.expired ?? false,
      stripeID: license?.stripeID ?? '',
      userID: license?.userID ?? '',
    });
    this.cdr.detectChanges();
    this.suspendUpdate = false;
  }

  createFormGroup(): void {
    this.formGroup = this.formBuilder.group<LicenseFormControls>({
      id: NotNullControl('', [Validators.required]),
      name: NotNullControl('', [Validators.required]),
      isFullAccess: NotNullControl(false),
      projectIDs: NotNullControl<string[]>([]),
      startedAt: NotNullControl<Date>(new Date(), [Validators.required]),
      expiresAt: NotNullControl<Date>(new Date(), [Validators.required]),
      expired: NotNullControl(false),
      stripeID: NotNullControl(undefined, [Validators.required]),
      userID: NotNullControl('', [Validators.required]),
    });
  }

  async uploadFile(file: MemoryFileUpdateEvent): Promise<void> {
    const params = await firstValuePromise(this.route.params);
    if (!params?.id) {
      return;
    }
    const dto: Partial<MemoryEncodingRequestDTO> = {
      id: file.memory?.id,
      device: DeviceTypes.Universal,
    };
    await this.licenseService.uploadFile(
      params.id as string,
      {
        fileUpload: { file: file.file },
        dto,
      },
      'documents',
    );
  }

  async submit(): Promise<void> {
    const result = await this.licenseService.createOne(this.formGroup?.value);
    if (result?.id) {
      void this.router.navigate([`/licenses/${result.id}`]);
    }
  }

  async updateServer(key: keyof LicenseFormControls): Promise<void> {
    if (this.isNewEntity || this.suspendUpdate) {
      return;
    }
    const dto: Partial<CreateDiffusionLicenseDTO> = {
      [key]: this.formGroup?.controls[key].value,
    };
    await this.licenseService.updateOne(
      this.formGroup?.controls.id.value as string,
      dto,
    );
  }
}
