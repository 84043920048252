import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizedString } from '@apophenia/platform';
import { EditableFormItem } from 'src/app/shared/components/dynamic-form-items/_editable-form';
import { SelectOption } from 'src/app/shared/components/selector/selector.component';

@Component({
  selector: 'app-editable-selector',
  templateUrl: './editable-selector.component.html',
  styleUrls: ['./editable-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableSelectorComponent),
      multi: true,
    },
  ],
})
export class EditableSelectorComponent<
  T = unknown,
> extends EditableFormItem<T> {
  @Input() options!: SelectOption<T>[] | null;
  @Input() textStyle?: Partial<CSSStyleDeclaration>;

  currentLabel?: string | LocalizedString;

  override toggleEdit(edit?: boolean): void {
    const stringValue = JSON.stringify(this.currentValue);
    this.currentLabel = (this.options ?? []).find(
      (x) => JSON.stringify(x.value) == stringValue,
    )?.label;
    super.toggleEdit(edit);
  }
}
