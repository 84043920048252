import { CommonModule } from '@angular/common';
import { inject, NgModule } from '@angular/core';
import { CoreModule } from '../../shared/components/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormItemsModule } from '../../shared/components/dynamic-form-items/dynamic-form-items.module';
import { LocalizeModule } from '../../shared/localization/localize.module';
import { CanActivateFn, RouterModule } from '@angular/router';
import { createServiceResolver } from '../../shared/utils/resolve-dataservice';
import { ProjectsService } from '../projects/projects.service';
import { RouteAccessLevel, RouteInfo } from '../../layout/routes';
import { NgZorroModule } from '../../shared/components/ng-zorro.module';
import { map, Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { LicenseService } from './license.service';
import { LicenseAdminListComponent } from './license-admin-list/license-admin-list.component';
import { LicenseUserListComponent } from './license-user-list/license-user-list.component';
import { LicenseCardComponent } from './License-card/license-card.component';
import { EntityInfoModule } from '../../shared/components/entity-info/entity-info.module';
import { UsersService } from '../users/users.service';
import { PlatformFileModule } from '../../shared/components/platform-files/platform-files.module';

const canViewLicenseList: CanActivateFn = (): Observable<boolean> => {
  const auth = inject(AuthService);
  return auth.tokenPayload$.pipe(
    map((user) => {
      return user?.isAdmin ?? false;
    }),
  );
};

const sharedModules = [
  CommonModule,
  CoreModule,
  FormsModule,
  ReactiveFormsModule,
  DynamicFormItemsModule,
  LocalizeModule,
  NgZorroModule,
  EntityInfoModule,
  PlatformFileModule,
];

@NgModule({
  declarations: [LicenseUserListComponent, LicenseCardComponent],
  imports: [
    ...sharedModules,
    RouterModule.forChild([
      {
        path: '',
        component: LicenseUserListComponent,
      },
      {
        path: ':id',
        component: LicenseCardComponent,
      },
    ]),
  ],
})
export class CurrentLicenseModule {}

@NgModule({
  declarations: [LicenseAdminListComponent],
  imports: [
    ...sharedModules,
    RouterModule.forChild([
      {
        path: '',
        component: LicenseAdminListComponent,
        canActivate: [canViewLicenseList],
      },
    ]),
  ],
})
export class LicenseModule {
  static get RouteInfo(): RouteInfo[] {
    return [
      {
        path: 'admin-licenses',
        loadChildren: () =>
          import('./licenses.module').then((m) => m.LicenseModule),
        resolve: {
          projects: createServiceResolver(ProjectsService),
          users: createServiceResolver(UsersService),
          licenses: createServiceResolver(LicenseService),
        },
        label: { FR: 'Licences Admin', EN: 'Licenses Admin' },
        icon: 'diff',
        visible: true,
        requiredAccess: RouteAccessLevel.Admin,
      },
      {
        path: 'licenses',
        loadChildren: () =>
          import('./licenses.module').then((m) => m.CurrentLicenseModule),
        resolve: {
          projects: createServiceResolver(ProjectsService),
          users: createServiceResolver(UsersService),
          licenses: createServiceResolver(LicenseService),
        },
        label: { FR: 'Licences', EN: 'Licenses' },
        icon: 'diff',
        visible: true,
        requiredAccess: RouteAccessLevel.Diffuser,
      },
      {
        path: 'licenses/:id',
        loadChildren: () =>
          import('./licenses.module').then((m) => m.CurrentLicenseModule),
        resolve: {
          projects: createServiceResolver(ProjectsService),
          users: createServiceResolver(UsersService),
          licenses: createServiceResolver(LicenseService),
        },
        visible: false,
      },
    ];
  }
}
