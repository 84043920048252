import { Component } from '@angular/core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationsService } from '../invitations.service';
import {
  EntityFormControls,
  NotNullControl,
} from '../../../shared/utils/form-controls';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateUserInvitationDTO, UserInvitation } from '@apophenia/platform';
import { LocalizedDict } from '../../../shared/localization/lozalize.models';
import { OrganismsService } from '../../organisms/organisms.service';
import { LicenseService } from '../../licenses/license.service';
import { SelectOption } from '../../../shared/components/selector/selector.component';
import { ArtistsService } from '../../artists/artists.service';
import { v4 as uuid } from 'uuid';

type InvitationFormControls = EntityFormControls<
  Partial<CreateUserInvitationDTO> & {
    id?: string;
  }
>;

@Component({
  selector: 'app-invitation-card',
  templateUrl: './invitation-card.component.html',
  styleUrls: ['./invitation-card.component.scss'],
})
export class UserInvitationCardComponent {
  isNewEntity = false;
  suspendUpdate = false;
  invitation$: Observable<UserInvitation>;
  artists$: Observable<SelectOption<string>[]>;
  organisms$: Observable<SelectOption<string>[]>;
  licenses$: Observable<SelectOption<string>[]>;

  formGroup?: FormGroup<InvitationFormControls>;

  translations: LocalizedDict = {
    createdAt: { EN: 'Creation date:', FR: 'Date de création:' },
    admin: { EN: 'Admin account', FR: 'Compte admin' },
    artist: { EN: 'Link artist account', FR: 'Associer un artiste' },
    client: { EN: 'Client access', FR: 'Accès client' },
    partner: { EN: 'Limited partner access', FR: 'Accès partenaire limité' },
    licenses: { EN: 'Diffusion licenses', FR: 'Licenses de diffusion' },
  };

  constructor(
    private invitationService: InvitationsService,
    private organismService: OrganismsService,
    private artistService: ArtistsService,
    private licenseService: LicenseService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.createFormGroup();
    this.invitation$ = this.route.params.pipe(
      map((params) => params.id as string),
      tap((id) => (this.isNewEntity = id == 'new')),
      switchMap((invitationID) =>
        this.invitationService.selectById(invitationID),
      ),
      map((inv) => inv ?? { id: uuid() }),
      tap((n) => {
        if (this.formGroup?.value?.id != n?.id) {
          this.updateFormGroup(n);
        }
      }),
    );
    this.organisms$ = this.organismService
      .selectAll()
      .pipe(map((x) => x.map((o) => ({ value: o.id, label: o.name }))));
    this.licenses$ = this.licenseService
      .selectAll()
      .pipe(
        map((x) =>
          x
            .filter((x) => !x.userID)
            .map((o) => ({ value: o.id, label: o.name })),
        ),
      );
    this.artists$ = this.artistService
      .selectAll()
      .pipe(
        map((x) =>
          x
            .filter((x) => x.user == null)
            .map((o) => ({ value: o.id, label: o.name })),
        ),
      );
  }

  updateFormGroup(inv?: UserInvitation): void {
    this.suspendUpdate = true;
    this.formGroup?.setValue({
      id: inv?.id as string,
      given_name: inv?.given_name ?? '',
      family_name: inv?.family_name ?? '',
      isAdmin: inv?.isAdmin ?? false,
      addStorageContainer: inv?.addStorageContainer ?? false,
      artistID: inv?.artistID ?? '',
      partners: inv?.partners ?? [],
      organisms: inv?.organisms ?? [],
      authenticID: inv?.authenticID ?? 0,
      licenses: inv?.licenses ?? [],
    });
    this.suspendUpdate = false;
  }

  createFormGroup(): void {
    this.formGroup = this.formBuilder.group<InvitationFormControls>({
      id: NotNullControl('', [Validators.required]),
      given_name: NotNullControl('', [Validators.required]),
      family_name: NotNullControl('', [Validators.required]),
      isAdmin: NotNullControl(false),
      addStorageContainer: NotNullControl(false),
      artistID: NotNullControl<string | undefined>(undefined),
      partners: NotNullControl<string[]>([]),
      organisms: NotNullControl<string[]>([]),
      authenticID: NotNullControl<number | undefined>(undefined),
      licenses: NotNullControl<string[]>([]),
    });
  }

  async nameChanged(): Promise<void> {
    if (this.isNewEntity) {
      return;
    }
    const dto: Partial<CreateUserInvitationDTO> = {
      given_name: this.formGroup?.controls.given_name.value,
      family_name: this.formGroup?.controls.family_name.value,
    };
    await this.invitationService.updateOne(
      this.formGroup?.controls.id.value as string,
      dto,
    );
  }

  async submit(): Promise<void> {
    const dto = this.formGroup?.value;
    delete dto?.authenticID;
    const result = await this.invitationService.createOne(dto);
    if (result?.id) {
      this.suspendUpdate = true;
      await this.router.navigate([`/invitations/${result.id}`]);
    }
  }

  async updateServer(key: keyof InvitationFormControls): Promise<void> {
    if (this.isNewEntity || this.suspendUpdate) {
      return;
    }
    const dto: Partial<CreateUserInvitationDTO> = {
      [key]: this.formGroup?.controls[key].value,
    };
    await this.invitationService.updateOne(
      this.formGroup?.controls.id.value as string,
      dto,
    );
  }
}
