import {
  IsArray,
  IsBoolean,
  IsDateString,
  IsNumber,
  IsOptional,
  IsString,
} from 'class-validator';

export class CreateDiffusionLicenseDTO {
  @IsString()
  public name: string;

  @IsOptional()
  @IsString({ each: true })
  @IsArray()
  public projectIDs?: string[];

  @IsOptional()
  @IsBoolean()
  public isFullAccess: boolean;

  @IsDateString()
  public startedAt: Date;
  @IsDateString()
  public expiresAt: Date;

  @IsOptional()
  @IsBoolean()
  public expired: boolean;

  @IsOptional()
  @IsString()
  public stripeID?: string;

  @IsString()
  public userID: string;
}

export class UpdateDiffusionLicenseDTO {
  @IsString()
  @IsOptional()
  public name?: string;

  @IsOptional()
  @IsString({ each: true })
  @IsArray()
  public projectIDs?: string[];

  @IsOptional()
  @IsBoolean()
  public isFullAccess?: boolean;

  @IsNumber()
  @IsOptional()
  public startedAt?: number;
  @IsNumber()
  @IsOptional()
  public expiresAt?: number;

  @IsOptional()
  @IsBoolean()
  public expired?: boolean;

  @IsOptional()
  @IsString()
  public stripeID?: string;

  @IsString()
  @IsOptional()
  public userID?: string;
}
