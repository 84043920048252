export const environment = {
  production: true,
  apiRoot: 'https://api.archivvr.com/api',
  publicFilesDomain: 'https://files.archivvr.com',

  audience: 'https://api.apophenia.prod/',
  auth_domain: 'https://eb9x7opamf.execute-api.ca-central-1.amazonaws.com/prod',

  client_id: 'BkGpzBALbDsAZXNIL2OgPCjuetHzzAv-juDaKWNQ88Y',

  expoEditorURL: 'https://www.archivvr.com/expo-editor',
};
