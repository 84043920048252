import { Component, Input } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { LicenseViewModel } from '../license.models';
import { LicenseService } from '../license.service';
import { ActivatedRoute } from '@angular/router';
import {
  CurrentUser,
  CurrentUsersService,
} from '../../../shared/services/current-users.service';
import { LocalizedDict } from '../../../shared/localization/lozalize.models';

export type LicenseListItem = Pick<
  LicenseViewModel,
  'id' | 'name' | 'expired' | 'expiration' | 'projectCount'
> & {
  url: string;
};

@Component({
  selector: 'app-user-license-list',
  templateUrl: './license-user-list.component.html',
  styleUrls: ['./license-user-list.component.scss'],
})
export class LicenseUserListComponent {
  @Input() userID?: string;

  user$: Observable<CurrentUser | undefined>;
  licenses$!: Observable<LicenseListItem[]>;
  expired$!: Observable<LicenseListItem[]>;

  translations: LocalizedDict = {
    current: { EN: 'Licenses', FR: 'Licenses' },
    expired: { EN: 'expired', FR: 'Expirées' },
  };

  constructor(
    private licenseService: LicenseService,
    private route: ActivatedRoute,
    private currentUser: CurrentUsersService,
  ) {
    this.user$ = this.currentUser.currentUser$;
    const list = this.route.params.pipe(
      map((params) => {
        const id = params.id as string;
        if (this.userID) {
          return this.userID;
        }
        return !id || id == 'current' ? this.currentUser.userID ?? id : id;
      }),
      switchMap((userID) =>
        this.licenseService.selectAll().pipe(
          map((x) =>
            x
              .filter((l) => l.userID == userID)
              .map((l) => ({
                name: l.name,
                id: l.id,
                expired: l.expired,
                expiration: l.expiration,
                projectCount: l.projectCount,
                url: `/licenses/${userID}/${l.id}`,
              })),
          ),
        ),
      ),
    );
    this.licenses$ = list.pipe(map((x) => x.filter((l) => !l.expired)));
    this.expired$ = list.pipe(map((x) => x.filter((l) => l.expired)));
  }
}
