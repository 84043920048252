import { Injectable } from '@angular/core';
import { CommonDataService } from '../../shared/services/_base-data.service';
import { UserInvitation } from '@apophenia/platform';

@Injectable({ providedIn: 'root' })
export class InvitationsService extends CommonDataService<UserInvitation> {
  loaded = false;

  protected get baseURL(): string {
    return '/users/invitations';
  }

  // protected override get loadDependencies(): Type<DataStore<unknown>>[] {
  //   return [OrganismsService, LicenseService];
  // }

  // protected override viewModel(
  //   entity: UserInvitation,
  // ): UserInvitationViewModel {
  //   // const organismService = this.getDependency(OrganismsService);
  //   // const licenseService = this.getDependency(LicenseService);
  //   return {
  //     ...entity,
  //     licenses: (entity.licenses ?? []).map((x) =>
  //       this.getShortEntity(licenseService.state.entities[x]),
  //     ),
  //     organisms: (entity.organisms ?? []).map((x) =>
  //       this.getShortEntity(organismService.state.entities[x]),
  //     ),
  //     partners: (entity.partners ?? []).map((x) =>
  //       this.getShortEntity(organismService.state.entities[x]),
  //     ),
  //   };
  // }

  // private getShortEntity<T extends { id: string; name: string }>(
  //   entity: T,
  // ): { id: string; name: string } {
  //   return { id: entity.id, name: entity.name };
  // }
}
