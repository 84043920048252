<div class="col">
  <app-action-bar>
    <button class="primary key-button" [routerLink]="'/invitations/new'">
      +
    </button>
  </app-action-bar>
  <div class="row row-wrap">
    <button
      *ngFor="let invitation of invitations$ | async"
      class="card invitation-row"
      [routerLink]="invitation.url"
    >
      <div class="title">
        {{ invitation.name }}
      </div>
      <button
        ngxClipboard
        type="button"
        rel="tooltip"
        class="primary key-button"
        [cbContent]="invitation.id"
        (click)="$event.stopPropagation()"
      >
        code
      </button>
    </button>
  </div>
</div>
